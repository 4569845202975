import jsPDF from "jspdf";
import "jspdf-autotable";
import copy from "copy-to-clipboard";
import es from "../translate/es.json";
import en from "../translate/en.json";
import pt from "../translate/pt.json";
import XLSX from "xlsx";
import { FaClock, FaCheck, FaTimes, FaTimesCircle, FaRegClock, FaRegBuilding } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import { TbFlag3 } from "react-icons/tb";
import { AiOutlineFileAdd, AiOutlineClockCircle, AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { IoLockClosedOutline } from "react-icons/io5";
import { FaCircleCheck } from "react-icons/fa6";
import { BiPackage } from "react-icons/bi";

//  ------- > GETTERS
export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

export const getClientDomain = (version) => {
  let domainV1 = "";
  let domainV2 = "";

  if (version === 2) return sessionStorage.getItem("apDomainV2") || domainV2;

  return sessionStorage.getItem("apDomain") || domainV1;
};

export const getClDomain = () => {
  return sessionStorage.getItem("clDomain") || null;
};

export const getNavigationPage = () => {
  return sessionStorage.getItem("currentNavPage") || null;
};

export const getPage = () => {
  return sessionStorage.getItem("currentPage") || null;
};

export const getValueSearchFilter = () => {
  return sessionStorage.getItem("valueSearchFilter" || null);
};

export const getAvaliblePlatforms = (value) => {
  const platformStr = sessionStorage.getItem("platforms");
  if (platformStr) return JSON.parse(platformStr);
  else return null;
};

export const getClasificacion = (dimension) => {
  switch (dimension) {
    case "A": case "S": case "A (EXT.) - (WIDTH X HEIGTH X LENGTH) 50 X 48 X 16 CM":
      return "P";
    case "B": case "M": case "B (EXT.) - (WIDTH X HEIGTH X LENGTH) 50 X 48 X 33 CM":
      return "M";
    case "C": case "L": case "C (EXT.) - (WIDTH X HEIGTH X LENGTH) 50 X 48 X 50 CM":
      return "G";
    case "D": case "XL":
      return "XG";
    default:
      break;
  }
};

//  ------- > SETTERS
export const setAvaliblePlatforms = (value) => {
  sessionStorage.setItem("platforms", JSON.stringify(value));
};

export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
};

export const setUserSession = (token, user) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
};


const countryAPIs = {
  pa: {
    prod: {
      apDomain: "https://api.myboxit.com/api/v1",
      apDomainV2: "https://api.myboxit.com/v2"
    },
    dev: {
      apDomain: "https://dev.myboxit.com/api/v1",
      apDomainV2: "https://dev.myboxit.com/v2",
    },
    localhost: {
      apDomain: "http://localhost:5000/api/v1",
      apDomainV2: "http://localhost:5000/v2"
    },
  },
  br: {
    prod: {
      apDomain: "https://d1dbb425nzoz86.cloudfront.net/api/v1",
      apDomainV2: "https://d1dbb425nzoz86.cloudfront.net/v2",
    },
    dev: {
      apDomain: "https://dev-api.myboxit.com.br/api/v1",
      apDomainV2: "https://dev-api.myboxit.com.br/v2",
    },
    localhost: {
      apDomain: "http://localhost:5001/api/v1",
      apDomainV2: "http://localhost:5001/v2",
    },
  },
  us: {
    // apDomain: "https://api.usa.com/api/v1",
    // apDomainV2: "https://api.usa.com/v2"
  },
};

export const setClientDomain = (location) => {
  sessionStorage.setItem("clDomain", location);
  const country = localStorage.getItem("country") || "pa";
  const config = countryAPIs[country];

  if (location === "bli.myboxit.com"){
    sessionStorage.setItem("apDomain", config.prod.apDomain);
    sessionStorage.setItem("apDomainV2", config.prod.apDomainV2);
  }else if(location === "dev-bli.myboxit.com"){
    sessionStorage.setItem("apDomain", config.dev.apDomain);
    sessionStorage.setItem("apDomainV2", config.dev.apDomainV2);
  }else{
    sessionStorage.setItem("apDomain", config.localhost.apDomain);
    sessionStorage.setItem("apDomainV2", config.localhost.apDomainV2);
  }

};

export const setNavigationPage = (value) => {
  sessionStorage.setItem("currentNavPage", value);
};

export const setPage = (value) => {
  sessionStorage.setItem("currentPage", value);
};

export const setValueSearchFilter = (value) => {
  sessionStorage.setItem("valueSearchFilter", value);
};

//  ------- > OTHER METHODS
export const round = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

export const checkConnectionStatus = () => navigator.onLine;

export const exportToPDF = (nombreReporte, filename, reportCol, reportRows, titleFile) => {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "px",
    format: "a4",
  });

  let logoBoxit = new Image();
  logoBoxit.src = "/img/logo-boxit.png";
  doc.addImage(logoBoxit, "PNG", 340, 20, 80, 30);
  const fecha = new Date();

  doc.setFont("Helvetica", "bold");
  doc.setFontSize(25);
  doc.text("Boxit Logística Inteligente", 40, 40);
  doc.setFont("Helvetica", "normal");
  doc.setFontSize(10);
  doc.text(
    `${fecha.getDate() < 10 ? `0${fecha.getDate()}` : fecha.getDate()}/${
      fecha.getMonth() + 1 < 10
        ? `0${fecha.getMonth() + 1}`
        : fecha.getMonth() + 1
    }/${fecha.getFullYear()} ${
      fecha.getHours() <= 12 ? fecha.getHours() : fecha.getHours() - 12
    }:${
      fecha.getMinutes() < 10 ? `0${fecha.getMinutes()}` : fecha.getMinutes()
    } ${fecha.getHours() < 12 ? "AM" : "PM"}`,
    40,
    50
  );
  doc.text(
    `Elaborado por: ${getUser().NombreSimple} ${getUser().ApellidoSimple}`,
    40,
    65
  );
  doc.text(`Reporte: ${nombreReporte}`, 40, 75);

  doc.setFont("Helvetica", "bold");
  doc.setFontSize(14);
  doc.text(titleFile, 170, 95);

  doc.autoTable({
    startY: 110,
    styles: { fontSize: 8 },
    columns: reportCol,
    body: reportRows,
  });

  doc.save(
    `${filename} ${
      fecha.getDate() < 10 ? `0${fecha.getDate()}` : fecha.getDate()
    }-${
      fecha.getMonth() + 1 < 10
        ? `0${fecha.getMonth() + 1}`
        : fecha.getMonth() + 1
    }-${fecha.getFullYear()}`
  );
};

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const statusDeliveryOrderFormat = (IdEstatus) => {
  switch (IdEstatus) {
    case 1:
      return "text-primary";
    case 2:
      return "text-info";
    case 5:
      return "text-warning";
    case 4:
      return "text-success";
    case 3:
      return "text-danger";
    case 6:
      return "text-danger";
    case 7:
      return "text-warning";
    default:
      break;
  }
};

export const handleMonth = (month) => {
  switch (month) {
    case 1:
    case "01":
      return translate("common.short_months.january");
    case 2:
    case "02":
      return translate("common.short_months.february");
    case 3:
    case "03":
      return translate("common.short_months.march");
    case 4:
    case "04":
      return translate("common.short_months.april");
    case 5:
    case "05":
      return translate("common.short_months.may");
    case 6:
    case "06":
      return translate("common.short_months.june");
    case 7:
    case "07":
      return translate("common.short_months.july");
    case 8:
    case "08":
      return translate("common.short_months.august");
    case 9:
    case "09":
      return translate("common.short_months.september");
    case 10:
      return translate("common.short_months.october");
    case 11:
      return translate("common.short_months.november");
    case 12:
      return translate("common.short_months.december");
    default:
      return "Invalid Month";
  }
};

export const scrollTo = (value) =>
  document.getElementById("mainLayout").scrollTo({
    top: value,
    behavior: "smooth",
  });

export const copyToClipboard = (value) => copy(value);

export const translate = (key) => {
  const lang = window.navigator.language?.split("-")[0];
  let langStorage = localStorage.getItem("language");
  let language = null;

  if (langStorage == null) {
    localStorage.setItem("language", lang);
    language = localStorage.getItem("language");
  } else {
    language = langStorage;
  }

  switch (language) {
    case "es":
      return es[key];
    case "pt":
      return pt[key];
    default:
      return en[key];
  }
};

export const getCountryCodeFromCoordinates = async (latitude, longitude) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    );
    const data = await response.json();
    const country = data.address.country_code;
    if (country) {
      return country;
    } else {
      console.log("Location not found.");
    }
  } catch (error) {
    console.error("Error fetching location:", error);
  }
};

export const getNameStatus = (id) => {
  switch (id) {
    case 1:
      return translate("global.status.new");
    case 2:
      return translate("global.status.processed");
    case 3:
      return translate("global.status.returned");
    case 4:
      return translate("global.status.completed");
    case 5:
      return translate("global.status.inTransit");
    case 6:
      return translate("global.status.canceled");
    default:
      break;
  }
};

export const getFormatDate = (date) => {
  const year = date.substring(4, 0);
  let month = date.substring(7, 5);
  let day = date.substring(10, 8);
  return `${day} ${getNameMonth(month)} ${year}`;
};

export const getFormatDateInput = (date) => {
  return date ? date.split('T')[0] : "" ;
}

export const getNameGender = (gender) => {
  switch (gender) {
    case "M":
      return translate("global.female")
    case "H":
      return translate("global.male")
    default:
      break;
}

}

export const getFormatDateHour = (date) => {

  // Crear un objeto Date a partir de la cadena de fecha
  const fecha = new Date(date);

  // Obtener la hora y los minutos
  const hora = fecha.toLocaleTimeString("es-ES", {
    hour: "2-digit",
    hour12: false,
  });
  const minutos = fecha.getMinutes();

  return `Hora: ${hora}, Minutos: ${minutos}`;
};

export const getNameMonth = (month) => {
  const monthFormat = Number(month);

  const monthMap = {
    1: "common.full_months.january",
    2: "common.full_months.february",
    3: "common.full_months.march",
    4: "common.full_months.april",
    5: "common.full_months.may",
    6: "common.full_months.june",
    7: "common.full_months.july",
    8: "common.full_months.august",
    9: "common.full_months.september",
    10: "common.full_months.october",
    11: "common.full_months.november",
    12: "common.full_months.december",
  };

  const monthName = monthMap[monthFormat] || "Invalid Month";
  return translate(monthName);
};

export const generateResponse = (result) => {
  const codeToMessageMap = {
    200: {key : "login.activate.USER_WAS_ACTIVATED_SUCCESSFULLY", icon: true},
    404: {key : "login.activate.USER_NOT_FOUND", icon: false},
    400: {key : "login.activate.USER_IS_ALREADY_ACTIVE", icon: true},
    401: {key : "login.activate.UNAUTHORIZED_USER", icon: false},
    500: {key : "login.forgotpwd.validate.errorServer", icon: false},
  };

  const code = result.code || 500;
  const messageKey = codeToMessageMap[code].key;
  const iconValue = codeToMessageMap[code].icon;

  return {
    code: code,
    message: translate(messageKey) || result.message,
    icon: iconValue
  };
};

export const formatDatePicker = (date, type) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return type ? `${day}/${month}/${year}` : `${year}-${month}-${day}`;
};

export const generateExcelAccounting = (data) => {
  const headers = Object.keys(data[0]);
  const dataArray = data.map((item) => Object.values(item));
  dataArray.unshift(headers);

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(dataArray);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, "Accounting.xlsx");
};

export const generateExcelFile = (data, name) => {
  const headers = Object.keys(data[0]);
  const dataArray = data.map((item) => Object.values(item));
  dataArray.unshift(headers);

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(dataArray);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `${name}.xlsx`);
};

export const formatNumDecimal = (num) => {
  return num.toFixed(2);
};

export const getAllMonths = () => {
  return [
    translate("common.full_months.january"),
    translate("common.full_months.february"),
    translate("common.full_months.march"),
    translate("common.full_months.april"),
    translate("common.full_months.may"),
    translate("common.full_months.june"),
    translate("common.full_months.july"),
    translate("common.full_months.august"),
    translate("common.full_months.september"),
    translate("common.full_months.october"),
    translate("common.full_months.november"),
    translate("common.full_months.december"),
  ];
};

export const areMoreThan3Months = (dateFrom, dateTo) => {
  const monthsDifference =
    (dateTo.getFullYear() - dateFrom.getFullYear()) * 12 +
    dateTo.getMonth() -
    dateFrom.getMonth();

  return monthsDifference > 3;
};

export const parseExcel = (file) => {
  const columnKeys = [];
  file.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (!columnKeys.includes(key?.toUpperCase()))
        columnKeys.push(key?.toUpperCase());
    });
  });

  const rows = file.map((row) =>
    columnKeys.map((key) =>
      row[key] !== undefined && row[key] !== null ? row[key] : "-"
    )
  );
  const cols = columnKeys.map((name, index) => ({
    name: String.fromCharCode(65 + index),
    key: index,
  }));

  const result = {
    rows: [columnKeys, ...rows],
    cols: cols,
  };

  return result;
};

const statusPackages = {
  1: "new",
  2: "reservedDoor",
  3: "inBoxit",
  4: "retired",
  5: "returned",
  6: "annulled",
  7: "inTransit",
};

const statusTypesPackages = {
  2: "regular",
  3: "returned",
};

export const setStatusObjTranslate = (obj) => {
  for (let i = 0; i < obj.length; i++) {
    const idEstatus = obj[i].IdEstatus;
    obj[i].EstatusBoxit = setStatusName(idEstatus);
  }
  return obj;
};

export const setStatusName = (id) => {
  let response = `global.status.${statusPackages[id]}`;
  return translate(response);
};

export const setStatusTypePackageName = (id) => {
  let response = `global.status.typePackage.${statusTypesPackages[id]}`;
  return translate(response);
};

export const handlerColorPackagesEstatus = value => {
  switch (value) {
      case 1:
          return 'text-warning';
      case 2:
          return 'text-info';
      case 3:
          return 'text-warning';
      case 4:
          return 'text-success';
      case 5:
          return 'text-danger';
      case 6:
          return 'text-danger'
      default:
          break;
  }
}

export const removeAccents = (str) => {
  return str
    .normalize("NFD") // Normaliza la cadena (quita los acentos)
    .replace(/[\u0300-\u036f]/g, ""); // Elimina los caracteres diacríticos
};

export const convertCoordinatesToArrayWithLatAndLng = (locations) => {
  const newArray = JSON.parse(JSON.stringify(locations));

  newArray.forEach((location) => {
    const coordinates = location.coordinates.split(",");
    location.lat = Number(coordinates[0]);
    location.lng = Number(coordinates[1]);
    delete location.coordinates;
  });
  return newArray;
};

export function formatFileSize(sizeInBytes) {
  if (sizeInBytes < 1024) {
    return sizeInBytes + " bytes";
  } else if (sizeInBytes < 1024 * 1024) {
    return (sizeInBytes / 1024).toFixed(2) + " KB";
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
    return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
  } else {
    return (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }
}

export function formatFileType(mimeType) {
  const parts = mimeType.split("/");
  if (parts.length === 2) {
    return parts[1];
  } else {
    return null;
  }
}

export function viewImageTypeBuffer(file, type) {
  if (type) {
    const base64Image = Buffer.from(file.data).toString("base64");
    const fileNext = `data:${type};base64,${base64Image}`;

    return fileNext;
  }
}

export function formatMoneyString(amount) {
  return parseFloat(amount).toFixed(2);
}

export function calculatePercentage(value, total) {
  value = parseFloat(value);
  total = parseFloat(total);

  if (isNaN(value) || isNaN(total) || total === 0) {
    return 0;
  }

  const percentage = (value / total) * 100;
  return `${Math.round(percentage)}%`;
}

export function getMonthsBilling() {
  const currentDate = new Date();
  const previousMonths = [];

  for (let i = 0; i < 3; i++) {
    let month = currentDate.getMonth() - i;
    let year = currentDate.getFullYear();

    if (month < 0) {
      month += 12;
      year -= 1;
    }

    previousMonths.push({
      month: (month + 1).toString().padStart(2, "0"),
      year: year.toString(),
      monthName: getNameMonth((month + 1).toString().padStart(2, "0")),
    });
  }

  return previousMonths;
}

const typePlans = {
  1: "Enterprise",
  2: "Business",
  3: "Basic",
};

export const getTypePlan = (id) => {
  return typePlans[id];
};

export const typeStatus = [
  {
    id: 1,
    text: translate("global.pending"),
    color: "#ffb400",
    background: "rgba(238, 143, 0, 0.1)",
    icon: <FaClock />,
    action: true,
  },
  {
    id: 2,
    text: translate("global.inProgress"),
    color: "#0099C9",
    background: "rgba(0, 153, 201, 0.1)",
    icon: <TfiReload />,
    actionAdmin: true,
  },
  {
    id: 3,
    text: translate("global.completed"),
    color: "#218E06",
    background: "rgba(33, 142, 6, 0.1)",
    icon: <FaCheck />,
    infoText: true,
  },
  {
    id: 4,
    text: translate("global.declined"),
    color: "#C86160",
    background: "rgba(200, 97, 96, 0.1)",
    icon: <FaTimes />,
    action: true,
    infoText: true,
  },
];

export const getStatusText = (status) => {
  const statusNumber = parseInt(status);
  const index = statusNumber - 1;

  if (index >= 0 && index < typeStatus.length) {
    return typeStatus[index];
  } else {
    // Handle invalid status values or out-of-range indices
    console.error(`Invalid status value: ${status}`);
    return null; // Or return a default status object
  }
};

export const getDateSeparate = (inputDate) => {
  const dateParts = inputDate.split("-");

  const result = {
    day: dateParts[2],
    month: dateParts[1],
    year: dateParts[0],
  };

  return result;
};

export const formatTransactionMonthly = (data) => {
  if (data && data.length > 0) {
    // Realizar el mapeo y crear el formato de factura mensual
    const transactionMonthlyFormat = data.map((item) => {
      return {
        [translate("global.nroIdentific")]: item.NumeroIdentificacion,
        [translate("global.Amount")]: formatter.format(item.Monto),
        [translate("global.dateDropOff")]: item.FechaEntrega,
        [translate("global.datePickup")]: item.FechaRetiro,
        [translate("global.email")]: item.EmailCliente,
        Locker: item.Locker,
      };
    });

    return transactionMonthlyFormat;
  } else {
    return {};
  }
};

export const formatListAdminBilling = (data) => {
  if (data && data.length > 0) {
    const response = data.map((item) => {
      return {
        ...item,
        IdEstatus: getStatusText(item.IdEstatus).text,
      };
    });

    return response;
  } else {
    return {};
  }
};

export const sqlDateFormat = (date, type) => {
  const newDate = new Date(date).toLocaleString("en-US").replace(",", "");
  if (type) {
    const sqlDate = new Date(date);
    return `${sqlDate.getFullYear()}-${(sqlDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${sqlDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${sqlDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${sqlDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${sqlDate.getSeconds().toString().padStart(2, "0")}`;
  }

  return newDate;
};

export const countIdStatus = (array) => {
  let count = {};
  typeStatus.forEach((status) => {
    count[status.id] = 0;
  });

  array.forEach((item) => {
    let idStatus = item.IdEstatus;
    if (count[idStatus] !== undefined) {
      count[idStatus]++;
    }
  });

  return count;
};

export const rangeYears = (start, end, step) => {
  const result = [];
  for (let i = start; i < end; i += step) {
    result.push(i);
  }
  return result;
};

export function getNameImage(data) {
  const parts = data.split("/");
  const fileName = parts[parts.length - 1];
  const match = fileName.match(/(.+)\.([a-zA-Z0-9]+)$/);

  return {
    name: fileName,
    type: match[2],
  };
}

export const statusOnline = (
  <div className="lx yz zd aac cbl">
    <div className="axw ajg uo ads aqm">
      <div className="ne rf ads ahy"></div>
    </div>
    <div className="md bah bxo"></div>
  </div>
);

export const statusOffline = (
  <div className="lx yz zd aac cbl">
    <div className="azi akr uo ads aqm">
      <div className="ne rf ads ahy"></div>
    </div>
    <div className="md bah bxo"></div>
  </div>
);

export const statusBusyDoor = (
  <div className="lx yz zd aac cbl">
  <div className="axw-yellow ajg-yellow uo ads aqm">
    <div className="ne rf ads ahy"></div>
  </div>
  <div className="md bah bxo"></div>
</div>
);

export const statusReservedDoor = (
  <div className="lx yz zd aac cbl">
    <div className="axw-online ajg-online uo ads aqm">
      <div className="ne rf ads ahy"></div>
    </div>
    <div className="md bah bxo"></div>
  </div>
);

export const statusExpiredDoor = (
  <div className="lx yz zd aac cbl">
  <div className="azi-offline akr-offline uo ads aqm">
    <div className="ne rf ads ahy"></div>
  </div>
  <div className="md bah bxo"></div>
</div>
);

export function verifyHideCountry() {
  var country = localStorage.getItem('country');

  if (country && country.toLowerCase() === 'br') {
    return false;
  } else {
    return true;
  }
}

export function verifyHideCountryItems(countryToHide) {
  var country = localStorage.getItem('country');

  if (country && Array.isArray(countryToHide) && countryToHide.map(c => c.toLowerCase()).includes(country.toLowerCase())) {
    return false; 
  } else {
    return true;
  }
}

export const getAvalibleTypesIncidents = (value) => {
  const result = sessionStorage.getItem("types-incidents");
  if (result) return JSON.parse(result);
  else return null;
};

export const setAvalibleTypesIncidents = (value) => {
  sessionStorage.setItem("types-incidents", JSON.stringify(value));
};

export const getAvaliblePriorityIncidents = (value) => {
  const result = sessionStorage.getItem("priority-incidents");
  if (result) return JSON.parse(result);
  else return null;
};

export const setAvaliblePriorityIncidents = (value) => {
  sessionStorage.setItem("priority-incidents", JSON.stringify(value));
};

export const getAvalibleStatusIncidents = (value) => {
  const result = sessionStorage.getItem("status-incidents");
  if (result) return JSON.parse(result);
  else return null;
};

export const setAvalibleStatusIncidents = (value) => {
  sessionStorage.setItem("status-incidents", JSON.stringify(value));
};

const incidentsVariables = {
  types:{
    1: "global.incidents.type.lockerApp",
    2: "global.incidents.type.hardware",
    3: "global.incidents.type.internet",
    4: "global.incidents.type.door",
    5: "global.incidents.type.payment",
    6: "global.incidents.type.package",
    7: "global.incidents.type.automaticIncident",
    8: "global.incidents.type.other",
    9: "global.incidents.type.dashboard"
  },
  priority: {
    1: "global.incidents.priority.high",
    2: "global.incidents.priority.medium",
    3: "global.incidents.priority.low"
  },
  status: {
    1: "global.incidents.status.new",
    2: "global.incidents.status.onHold",
    3: "global.incidents.status.inProcess",
    4: "global.incidents.status.solved",
    5: "global.incidents.status.closed",
    6: "global.incidents.status.canceled"
  }
};

const doorsVariables = {
  movements:{
    1: "global.movementsTypes.delivery",
    2: "global.movementsTypes.pickupClient",
    8: "global.movementsTypes.packExpiredCollected"
  }
};

export const getNameIncidentsTypes = (id) => {
  return incidentsVariables.types[id] ? translate(incidentsVariables.types[id]) : "";
};

export const getNameIncidentsPriority = (id) => {
  return incidentsVariables.priority[id] ? translate(incidentsVariables.priority[id]) : "";
};

export const getNameIncidentsStatus = (id) => {
  return incidentsVariables.status[id] ? translate(incidentsVariables.status[id]) : "";
};

export const getNameDoorsMovements = (id) => {
  return doorsVariables.movements[id] ? translate(doorsVariables.movements[id])?.toUpperCase() : "";
};


export const todayDate = new Date();
export const firstDayOfMonth = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
const lastDayOfMonth = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);

export const formatDate = (date) => {
  return date.toISOString().split('T')[0];
};

export const defaultFilterIncidents = {
  typeIncident: [],
  priorityIncident: [],
  statusIncident: [1, 2, 3],
  startDate: [formatDate(firstDayOfMonth)],
  endDate: [formatDate(todayDate)],
};

export  const getFlagPriorityIncident = (priorityId) => {
  switch (priorityId) {
    case 1:
      return <TbFlag3 style={{ color: "red" }} />;
    case 2:
      return <TbFlag3 style={{ color: "#FFA500" }} />;
    case 3:
      return <TbFlag3 style={{ color: "blue" }} />;
    default:
      return null;
  }
};

export  const getColorPriorityIncident = (priorityId) => {
  switch (priorityId) {
    case 1:
      return "danger";
    case 2:
      return "warning";
    case 3:
      return "primary" ;
    default:
      return null;
  }
};

export  const getIconStatusIncident = (id) => {
  switch (id) {
    case 1:
      return <AiOutlineFileAdd />;
    case 2:
      return <AiOutlineClockCircle />;
    case 3:
      return <TfiReload />;
      case 4:
        return <AiOutlineCheckCircle />;
      case 5:
        return <IoLockClosedOutline /> ;
        case 6:
          return <AiOutlineCloseCircle />;
        case 7:
          return <AiOutlineCheckCircle />;
    default:
      return null;
  }
};

export const getTimeFormat = (dateString) => {
  
  const date = new Date(dateString);

  // Get hours, minutes, and seconds
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  const result = hours + ':' + minutes + ':' + seconds;

  return result;
}

export const defaultCountryProperties = [
  // { code: "us", name: "USA", flag: "/img/usa_flag.png", actived: false },
  { code: "pa", name: "PANAMÁ", flag: "/img/pa_flag.png", actived: true },
  { code: "br", name: "BRASIL", flag: "/img/br_flag.png", actived: true },
];

export const findActionsByPath = (data, path) => {

  const searchOptions = (options) => {
    for (const option of options) {
        if (option.path === path && option.actions) {
            return option.actions;
        } else if (option.subNav && option.subNav.length > 0) {
            const actions = searchOptions(option.subNav);
            if (actions.length > 0) {
                return actions;
            }
        }
    }
    return [];
}

return searchOptions(data.options);
  }

  export  const getIconStatusClients = (id) => {
    switch (id) {
      case 1:
        return <FaCircleCheck />;
      case 2:
        return <FaTimesCircle />;
      case 3:
        return <FaRegClock />;
      default:
        return "";
    }
  };

  export const getColorStatusClient = (id) => {
    switch (id) {
      case 1:
        return "success";
      case 2:
        return "alert";
      case 3:
        return "warning";
      default:
        return "";
  }
}

  export  const getNameStatusClients = (id) => {
    switch (id) {
      case 1:
        return  translate("global.state.active");
      case 2:
        return translate("global.state.inactive");
      case 3:
        return translate("global.pendingActive");
      default:
        return "";
    }
  };


  
  export  const getLabelTipoCliente = (id) => {
    switch (id) {
      case 1:
        return <span className="badge-status  text-bold m-0"><BiPackage className="mr-1" size={18}/>{translate("global.packageLlego")}</span>;
      case 7:
        return <span className="badge-status fucsia text-bold m-0"><FaRegBuilding className="mr-1" size={16}/> {translate("global.BLI")}</span>;
      default:
        return "";
    }
  };

  export const getNameRoleBli = (id) => {
    switch (id) {
      case 1:
        return translate("global.typesRoleBli.1");
        case 2:
        return translate("global.typesRoleBli.2");
        case 3:
          return translate("global.typesRoleBli.3");
        case 4:
          return translate("global.typesRoleBli.4");
          case 5:
            return translate("global.typesRoleBli.5");
            case 6:
              return translate("global.typesRoleBli.6");
              case 7:
          return translate("global.typesRoleBli.7");
      default:
        return "";
    }
  };

  export  const getNameTipoPlanCliente = (id) => {
    switch (id) {
      case 1:
        return translate("global.status.typePlanClient.normalBasic");
      case 2:
        return translate("global.status.typePlanClient.normalPremium");
      case 3:
        return translate("global.status.typePlanClient.basicBusiness");
        case 4:
          return translate("global.status.typePlanClient.premiumBusiness");
          case 5:
          return translate("global.status.typePlanClient.boxitStore");
          case 6:
          return translate("global.status.typePlanClient.vip");
          case 7:
          return translate("global.status.typePlanClient.preferential");
      default:
        return "";
    }
  };

  